import {createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"


//get main link
const url = process.env.REACT_APP_BACKEND

// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'))


const initialState = {
  user: user ? user : null,
  isLoading: false,
  isLoggedIn: false,
}

//forget password
export const forgetPassword = createAsyncThunk(
  'auth/forgetPassword',
  async(data, thunkAPI) =>{
    try {
      const response = await axios.post(`${url}/auth/forgetPassword`, data)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//change password
export const changePassword = createAsyncThunk(
'auth/changePassword',
async(data, thunkAPI) =>{
  try {
    const response = await axios.post(`${url}/auth/changePassword`, data)
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
}
)


//login 
export const login = createAsyncThunk(
  'auth/login',
  async (data, thunkAPI) => {
    try{
       const response = await axios.post(`${url}/auth/login`, data)
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });



  export const addUser = createAsyncThunk(
    'auth/signup',
    async(data, thunkAPI) => {
      try{
        const response = await axios.post(`${url}/auth/signUp`, data)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
        
      }
    }
  )

  //googleLogin 
export const googlLogin = createAsyncThunk(
  'auth/googlLogin',
  async (data, thunkAPI) => {
    try{
     const response =  await localStorage.setItem('user', JSON.stringify(data))
  
    return response.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await localStorage.removeItem('user')
});


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

  },
  extraReducers:  (builder) => {
    (builder)
  
  .addCase(login.fulfilled, (state, action) => {
    state.isLoggedIn = true;
    state.user = action.payload;
    state.error = null;
  })
  .addCase(login.pending, (state, action) => {
    state.isLoggedIn = false;
    state.user = null;
    state.error = action.payload;
  })
  .addCase(login.rejected, (state) => {
    state.isLoggedIn = false;
    state.user = null;
    state.error = null;
  })
  .addCase(googlLogin.fulfilled, (state, action) =>{
    state.isLoggedIn=true;
    state.user = action.payload;
    state.error = null;
  })
  .addCase(googlLogin.pending, (state, action) => {
    state.isLoggedIn = false;
    state.user = null;
    state.error = action.payload;
  })
  .addCase(googlLogin.rejected, (state) => {
    state.isLoggedIn = false;
    state.user = null;
    state.error = null;
  })
  .addCase(logout.fulfilled, (state)=>{
    state.isLoggedIn = false;
    state.user = null;
  })
  


  },
})

// export const { loginSuccess, loginFailure, logout } = authSlice.actions;

// export const login = (data) => async (dispatch) => {  
//     try{
//       const response = await axios.post(`${url}/auth/login`, data)
//       if (response.data) {
//         localStorage.setItem('user', JSON.stringify(response.data))
//       }
//       const user = await response.data
//       dispatch(loginSuccess(user))
//     }catch(error){
//       dispatch(loginFailure(error.message));
//     }
  
// }

// export const googlLogin = (data) => async (dispatch) => {
//     try{
   
//         await localStorage.setItem('user', JSON.stringify(data))
      
//       const user = data.json
//       dispatch(loginSuccess(user))
      
//     }catch(error){
//       dispatch(loginFailure(error.message));
//     }
  
// }




export const selectisLoggedIn = (state) => state.auth.isLoggedIn;
export default authSlice.reducer


