import './App.css'
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import Home from "./pages/Home"
import Job from "./components/Job"
import SingleJob from "./components/SingleJob"
import LoginReg from './pages/LoginReg'
import MyProfile from './pages/MyProfile'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Profile from './pages/Profile'
import JobList from './pages/JobList'
import AllJobs from './pages/AllJobs'
import FeaturedJobs from './pages/FeaturedJobs'
import Login from './pages/Login'
import Register from './pages/Register'
import { useDispatch } from 'react-redux'
import {  getUser } from './feature/authSlice' 
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import JobProfile from './pages/JobProfile'
import PrivateRoute from './components/content/PrivateRoute'
import { selectisLoggedIn } from './feature/authSlice'
import { useNavigate } from 'react-router-dom'
import Spinner1 from './components/Spinner1'
import AllWork from './components/jobCard/AllWork'
import AllEducation from './components/jobCard/AllEducation'
import AllCertifications from './components/jobCard/AllCertifications'

toast.configure()





function App() {
  const isLoggedIn = useSelector(selectisLoggedIn)
  const dispatch = useDispatch()
const navigate = useNavigate()


  // useEffect(() => {
   
  //     const cookieValue = document.cookie.replace(
  //     /(?:(?:^|.*;\s*)user\s*\=\s*([^;]*).*$)|^.*$/,
  //     '$1'
  //   );
  //   const decodedCookieValue = decodeURIComponent(cookieValue);
  //   try {
  //     const cookieUser = JSON.parse(decodedCookieValue);
  //     //setUser(cookieUser);
  //     console.log(cookieUser)
  //     localStorage.setItem('user', JSON.stringify(cookieUser))
  //   } catch (error) {
  //     console.error('Error parsing cookie:', error);
  //     //setUser(null);
  //   }
    
    
  // }, []);

  // useEffect(() => {
  //   if (window.location.href.includes('/?user')) {
  //   const users = new URLSearchParams(window.location.search).get('user')
  //   const data = JSON.parse(decodeURIComponent(users));
  
  //     dispatch(googlLogin(data)).then(() => {
  //       navigate('/userProfile'); 
  //     });
      
  //   }
   
  // }, [dispatch, navigate])


  return (
    <>
 
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/job/" element={<Job />} />
        <Route path="/singleJob" element={<SingleJob />} />
        <Route path='/auths' element={<LoginReg />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Register />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/joblist' element={<JobList />} />
        <Route path='/alljobs' element={<AllJobs />} />
        <Route path='/userProfile'  element={<JobProfile />}/>
        <Route path='/spinner' element={<Spinner1 />} />
        <Route path='/experience' element={<AllWork />}/>
        <Route path='/education' element={<AllEducation />} />
        <Route path='/certifications' element={<AllCertifications />} />
      
       </Routes>
   
  
    </>
   
  
  );
}

export default App;
